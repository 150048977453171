@import "styles/config";

$accentDark: #ff4337;
$accentLight: #e85234;
$accentGreen: #009b00;

$height: 16px;
$halfheight: ($height/2);
$thickness: 120%; // increase to make the line thicker
$offset: 4px;
$backgroundcolor: white;

$drawer-mask-background: #ff4337;

$locationCard-button-effect: true !default;

.gm-bundled-control-on-bottom {
  bottom: 220px !important;
}

.empty-image {
  display: none !important;
}

button {
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.7 !important;
  }
}

%menuItem {
  transition: all 0.3s ease-in-out;
  border: 1px solid white;

  &:hover {
    border: 1px solid $accentDark !important;
    box-shadow: 7px 7px $accentDark !important;
  }

  @include media-breakpoint-up(md) {
    height: 13rem;
  }
}

%tipGroup {
  span {
    @include media-breakpoint-up(md) {
      font-size: 18px !important;
    }
    font-size: 14px !important;
  }
}

%location {
  background-color: white;

  .location-container {
    &--header {
      box-shadow: none;
    }
    &--footer {
      display: none !important;
    }
    &--cards {
      & .nothing {
        img {
          max-width: 30rem !important;
        }
      }
    }
  }

  .body-title {
    margin-right: 6rem !important;
  }
  .body-art {
    width: 5rem;
  }
}

%locationCard {
  background: white;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 7px 7px $accentDark !important;
  }
  .location-card:first-child {
    border-radius: 0;
    border-bottom: 2px solid $accentDark;
  }

  button {
    padding: 0.75rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 0.6;
    }
  }
}

%nav {
  box-shadow: 0px 5px 3px -2px #ff4337 !important;
  .nav-list {
    li:hover {
      span {
        transition: all 0.3s ease-in-out;
        color: #ff4337 !important;
      }
    }
  }
}

%header {
  .header {
    &-wrapper {
      box-shadow: 0px 5px 3px -2px #ff4337 !important;
      &--left {
        & .logo {
          & img {
            max-height: 3rem;
          }
        }
      }
    }
  }
}

%group {
  .group {
    display: inline-block;
    position: relative;
    &-info {
      margin-top: 10px !important;
      margin-bottom: 0;
      padding: 1rem !important;
      &-container {
        border-bottom: 0px;
      }
    }

    &-divider {
      background: $backgroundcolor;
      position: relative;
      height: $height;
      z-index: 1;
      margin-bottom: 1.5rem;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
      }
      &:before {
        height: ($height - $offset);
        top: $thickness;
        background: linear-gradient(
              -135deg,
              $accentDark $halfheight,
              transparent 0
            )
            0 $halfheight,
          linear-gradient(135deg, $accentDark $halfheight, transparent 0) 0
            $halfheight;
        background-position: top left;
        background-repeat: repeat-x;
        background-size: $height $height;
      }
      &:after {
        height: $height;
        top: 100%;
        background: linear-gradient(
              -135deg,
              $backgroundcolor $halfheight,
              transparent 0
            )
            0 $halfheight,
          linear-gradient(135deg, $backgroundcolor $halfheight, transparent 0) 0
            $halfheight;
        background-position: top left;
        background-repeat: repeat-x;
        background-size: $height $height;
      }
    }
  }
}

%modifierItem {
  border-radius: 0.25rem !important;

  &:hover {
    box-shadow: 5px 5px $accentDark !important;
  }
  &[active="1"]:hover {
    box-shadow: 5px 5px $accentGreen !important;
  }
  .modifierItem {
    &-name {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      flex-grow: 0.2;
    }
    &-desc {
      width: 110%;
    }

    &-price {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-right: 0.5rem !important;
    }

    &-info {
      display: flex;
      flex-direction: column;
      padding-right: 2.25rem !important;
    }

    &-remove {
      background-color: white;
    }
  }
}

%footerButton {
  border-radius: 5;
  & > button {
    border-radius: 5 !important;
  }
}

%quantitySelector {
  .quantitySelector {
    &-container {
      .quantity-content {
        width: 40px;
        height: 40px;
        margin: 0 0.5rem;
        border-radius: 50%;
      }

      .quantity-btn {
        width: 40px;
        height: 40px;

        &:first-child {
          span {
            padding-bottom: 0.3rem;
          }
        }
        span {
          color: $accentGreen;
          padding: 0 !important;
        }
      }
    }
  }
}

%cartItem {
  @include elevation(2);
  box-shadow: none;
  border-width: 2px !important;

  .cartItem {
    &-info {
      padding: 0.75rem 0.75rem 0 0.75rem !important;
    }
    &-desc {
      min-height: 80px !important;
      align-items: center;
      display: flex;
    }
    &-actions {
      border-top: 1px solid $accentGreen !important;
      &-btn {
        &:first-child:not(:only-child) {
          border-right: 1px solid $accentGreen !important;
        }
      }
    }
  }
}

%itemDetails-tabs {
  .itemDetails-tabs {
    &-nav-container {
      display: none;
      border-bottom: 2px solid $accentGreen;
    }
  }
}

%itemDetails {
  .itemDetails {
    &-notes {
      label {
        display: none;
      }
    }

    &-footer {
      -webkit-box-shadow: 0px -6px 44px -4px #ff433752;
      -moz-box-shadow: 0px -6px 44px -4px #ff433752;
      box-shadow: 0px -6px 44px -4px #ff433752;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;
    }
  }
}

%nav {
  .nav {
    &-container {
      box-shadow: 0px 2px 4px #ff4337;
    }
  }
}

%navIcon {
  & img {
    max-width: 2.5rem !important;
    max-height: 2.5rem !important;
  }
}

%packingItem {
  width: 95%;
  margin: 1rem auto;
  padding: 1rem 2rem 1rem 1rem !important;
  transition: all 0.3s ease-in-out;
  min-height: 6rem;
  &:hover {
    box-shadow: 7px 7px $accentDark !important;
  }
  &[active="1"]:hover {
    box-shadow: 7px 7px $accentGreen !important;
  }
}

%locationInfo {
  display: none !important;
}

%navScrollShadow {
  display: none !important;
}

%collapsable {
  .toggle i {
    border-color: $accentDark;
  }
}

%empty {
  max-width: 27rem;
  & img {
    max-width: 27rem !important;
  }
}

%cart {
  button {
    transition: all 0.3s ease-in-out;
  }
  button:hover {
    opacity: 0.8 !important;
  }
}

%confirm {
  button {
    transition: all 0.3s ease-in-out;
  }
  button:hover {
    opacity: 0.6 !important;
  }
}

%upsellItem {
  transition: all 0.3s ease-in-out;
  box-shadow: none !important;
  &:hover {
    box-shadow: 7px 7px $accentDark !important;
  }
  &[active="1"]:hover {
    box-shadow: 7px 7px $accentGreen !important;
  }
  .upsellItem {
    &-info {
      &__actions {
        border-top: 0px;
      }
    }
  }
}

%giftCard {
  .giftCard {
    &-left {
      &--input {
        color: black !important;
      }
      &--value {
        button {
          border-radius: 50px !important;
          span {
            font-size: 1rem;
            font-weight: 300;
            font-family: "Souvenir-Light";
          }
        }
      }
    }

    &-right {
      &--header {
        border-bottom: 1.5px solid $accentGreen;
      }
      &--field {
        border-bottom: 1px solid black;
      }
    }
  }
}

.tooltip-icon {
  @media only screen and (max-width: 450px) {
    padding-left: 0 !important;
    & > img {
      height: 14px !important;
    }
  }
}

.menu-item {
  @media only screen and (max-width: 500px) {
    height: 12rem !important;
  }
}
